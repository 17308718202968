.title1 {
  font-size: 48px;
  line-height: 1;
  font-weight: 300;
  color: var(--textColor);
}
@media (max-width: 1023px) {
  .title1 {
    font-size: 36px;
    line-height: 1.11111111;
  }
}
.title2 {
  font-size: 36px;
  line-height: 1.11111111;
  font-weight: 300;
  color: var(--textColor);
}
@media (max-width: 1023px) {
  .title2 {
    font-size: 24px;
    line-height: 1.33333333;
  }
}
.title3 {
  font-size: 30px;
  line-height: 1.2;
  font-weight: 300;
  color: var(--textColor);
}
@media (max-width: 1023px) {
  .title3 {
    font-size: 20px;
    line-height: 1.4;
  }
}
.title4 {
  font-size: 24px;
  line-height: 1.33333333;
  font-weight: 300;
  color: var(--textColor);
}
@media (max-width: 1023px) {
  .title4 {
    font-size: 20px;
    line-height: 1.4;
  }
}
.title5 {
  font-size: 20px;
  line-height: 1.3;
  font-weight: 300;
  color: var(--textColor);
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.buttonBasics {
  display: inline-block;
  position: relative;
  background-color: #fff;
  font-size: 14px;
  line-height: 1.42857143;
  font-weight: bold;
  cursor: pointer;
  color: #577A63;
  appearance: none;
  border-radius: 6px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: 1px solid #EAEAEA;
  transition: all 0.4s;
}
.buttonBasics:hover,
.buttonBasics:focus {
  color: #577A63;
  background-color: #EAEAEA;
}
.button {
  display: inline-block;
  position: relative;
  background-color: #fff;
  font-size: 14px;
  line-height: 1.42857143;
  font-weight: bold;
  cursor: pointer;
  color: #577A63;
  appearance: none;
  border-radius: 6px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: 1px solid #EAEAEA;
  transition: all 0.4s;
  padding: 9px 32px 9px 17px;
  box-shadow: 0 1px 4px rgba(25, 33, 61, 0.08);
}
.button:hover,
.button:focus {
  color: #577A63;
  background-color: #EAEAEA;
}
.button:before {
  content: '';
  position: absolute;
  top: 50%;
  right: 17px;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  mask-size: 100% 100%;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/arrow-right.svg);
  background-color: #577A63;
}
@media (max-width: 767px) {
  .button {
    width: 100%;
  }
}
.button2 {
  display: inline-block;
  position: relative;
  background-color: #fff;
  font-size: 14px;
  line-height: 1.42857143;
  font-weight: bold;
  cursor: pointer;
  color: #577A63;
  appearance: none;
  border-radius: 6px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: 1px solid #EAEAEA;
  transition: all 0.4s;
  padding: 9px 17px;
}
.button2:hover,
.button2:focus {
  color: #577A63;
  background-color: #EAEAEA;
}
.buttonDownload {
  display: inline-block;
  position: relative;
  background-color: #fff;
  font-size: 14px;
  line-height: 1.42857143;
  font-weight: bold;
  cursor: pointer;
  color: #577A63;
  appearance: none;
  border-radius: 6px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: 1px solid #EAEAEA;
  transition: all 0.4s;
  padding: 9px 32px 9px 17px;
  box-shadow: 0 1px 4px rgba(25, 33, 61, 0.08);
}
.buttonDownload:hover,
.buttonDownload:focus {
  color: #577A63;
  background-color: #EAEAEA;
}
.buttonDownload:before {
  content: '';
  position: absolute;
  top: 50%;
  right: 17px;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  mask-size: 100% 100%;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/arrow-right.svg);
  background-color: #577A63;
}
@media (max-width: 767px) {
  .buttonDownload {
    width: 100%;
  }
}
.buttonDownload:before {
  mask-image: url(/images/arrow-bottom.svg);
}
div.navi {
  float: left;
}
div.navi > .item {
  float: left;
}
div.navi > .item > .menu {
  display: block;
}
@media (min-width: 1024px) {
  .togglenavigation,
  .mobile-navigation {
    display: none;
  }
}
div.sub1 {
  display: flex;
  gap: 24px;
}
div.sub1 > .item > .menu {
  font-size: 16px;
  line-height: 1.375;
  color: #777777;
}
div.sub1 > .item > .menu:hover,
div.sub1 > .item > .menu:focus {
  color: #577A63;
}
div.sub1 > .item > .menu.path {
  font-weight: bold;
  color: #577A63;
}
.container--head {
  margin: 20px 0;
  align-items: flex-end;
}
.footarea {
  flex-direction: row;
  align-items: flex-start;
  padding-top: 47px;
  padding-bottom: 16px;
  gap: 40px 20px;
  border-top: 1px solid #B1B1B1;
}
.footpart {
  align-items: flex-start;
}
.footpart--one {
  width: calc(100% / 1106 * 303);
}
.footpart--two {
  width: calc(100% / 1106 * 432);
  margin-top: 37px;
}
.footpart--three {
  text-align: right;
  width: calc(100% / 1106 * 166);
  margin-top: 37px;
}
.footpart--four {
  width: calc(100% / 1106 * 303);
  margin: 0;
  padding: 0;
  border: none;
}
.footpart--five {
  width: calc(100% / 1106 * 166);
}
.area--one .unitThree {
  width: calc(25% - 22px);
}
/*# sourceMappingURL=./screen-large.css.map */